import { mock } from 'ts-mockito';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { Params } from '@angular/router';
import { ParamsModel } from '@app/models/params-model';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';

@Injectable({
  providedIn: 'root'
})
export class PsApiService {
  private apiUrl = environment.apiBase.url;
  private settingApiUrl = environment.coreEntityApiBase.url;
  constructor(
    private http: HttpClient,
    private selectedCompanyService: SelectedCompanyService
  ) {}

  getPostedSpeedEvents(paramsSent: ParamsModel): Observable<any> {
    const url = `${
      this.apiUrl
    }/speeding_events/${this.selectedCompanyService.getCurrentCompanyId()}`;
    const params = this.createParams(paramsSent);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getPostedSpeedAssets(paramsSent: ParamsModel): Observable<any> {
    const url = `${
      this.apiUrl
    }/asset_summary/${this.selectedCompanyService.getCurrentCompanyId()}`;
    const params = this.createParams(paramsSent);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getThresholdSetting(settingName: string): Observable<any> {
    const url = `${this.settingApiUrl}/settings`;
    const queryParams = {
      ownerId: this.selectedCompanyService.getCurrentCompanyId(),
      name: settingName,
      ownerType: 'COMPANY'
    };
    const params = this.createParams(queryParams);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getThresholdDefinition(settingName: string) {
    const url = `${this.settingApiUrl}/definitions/${settingName}`;

    return this.http.get<any>(url, { observe: 'response' });
  }

  getChartData(paramsSent: ParamsModel, view: string): Observable<any> {
    const companyId =
      paramsSent.company_id ||
      this.selectedCompanyService.getCurrentCompanyId();
    const url = `${this.apiUrl}/${view}/${companyId}/chart`;
    const params = this.createParams(paramsSent);
    return this.http.get<any>(url, { params, observe: 'response' });
  }

  getTopCriticalAssets(paramsSent: ParamsModel): Observable<any> {
    const url = `${
      this.apiUrl
    }/asset_summary/${this.selectedCompanyService.getCurrentCompanyId()}/top`;
    const params = this.createParams(paramsSent);

    return this.http.get<any>(url, { params, observe: 'response' });
  }

  createParams(selection: ParamsModel): Params {
    const queryParams: Params = {};
    Object.keys(selection).forEach((key) => {
      const value = selection[key];
      if (!value) {
        delete queryParams[key];
      } else {
        queryParams[key] = value;
      }
    });
    return queryParams;
  }

  returnMockData(data: any, totalCount: Number): Observable<any> {
    const mockResponse = {
      status: 200,
      statusText: 'OK',
      body: data,
      headers: { get: (_) => totalCount }
    };
    return of(mockResponse);
  }
}
